import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/common';
import { useTranslation } from 'react-i18next';
import s from './EmailLoginForm.module.scss';
import { Button } from 'components/ui/Button';
import { PasswordRequirements } from 'components/password-requirements';
import useEmailLogin from 'hooks/use-email-login';
import { AppRoutes } from 'config/routes';

interface EmailLoginFormValues {
  email: string;
  password: string;
}

export const EmailLoginForm = () => {
  const { t } = useTranslation();

  const { isLoading, login } = useEmailLogin();

  const initialValues: EmailLoginFormValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('Email is required'))
      .test('is-valid-email', t('Invalid email address entered!'), (value) => {
        // Custom email validation logic
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return value ? emailRegex.test(value) : false;
      }),
    password: Yup.string()
      .required(t('Password is required'))
      .min(8, t('Password must be at least 8 characters'))
      .max(64, t('Password must be less than 64 characters'))
      .matches(/[a-zа-яё]/, t('Password must contain at least one lowercase letter'))
      .matches(/[A-ZА-ЯЁ]/, t('Password must contain at least one uppercase letter')),
  });

  const onSubmit = async (
    values: EmailLoginFormValues,
    actions: FormikHelpers<EmailLoginFormValues>,
  ) => {
    // Implement your login logic here
    console.log('Login with:', values);
    actions.setSubmitting(false);

    await login(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, isSubmitting }) => (
        <Form className={s.form}>
          <InputField
            name="email"
            type="email"
            label={t('Email')}
            placeholder="example@example.com"
            required
          />
          <InputField
            name="password"
            type="password"
            label={t('Password')}
            placeholder={t('Enter your password')}
            required
          />
          {/* Password Requirements Feedback */}
          <div className={s.passwordRequirements}>
            <PasswordRequirements password={values.password} />
          </div>
          <div className={s.forgotLink}>
            <a
              style={{ color: 'black' }}
              href={AppRoutes.Auth.Home + AppRoutes.Auth.ForgotPassword}
            >
              {t('Forgot Password?')}
            </a>
          </div>

          <div className={s.buttonContainer}>
            <Button type="primary" disabled={isSubmitting}>
              {isLoading ? t('Loading...') : t('Login')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
