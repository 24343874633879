import { gql } from 'graphql-request';

// query App5182dd71_Document($status: String, $searchQuery: String) {
// status_id: $status
// agreement: $searchQuery

export const GET_ALL_OPERATIONAL_DOCUMETNS = gql`
  query App5182dd71_OperationalRequest($statusIds: [String!], $input: String) {
    app5182dd71_OperationalRequest {
      getList(filters: { input: $input, statusIds: $statusIds }, pagination: { limit: 1000 }) {
        data {
          id
          version
          statusId
          orgOwnerId
          employeeOwnerFIO
          employeeReceivingFIO
          agreementName
          number
          requestDate
          shippingDate
          deliveryBasis
          supplyAddress
          goodsType
          quantity
          comments
          createdAt
          updatedAt
        }
      }
    }
  }
`;
