import { Divider } from 'antd';
import styles from './DetailRequest.module.scss';
import { Button } from 'components/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOperationalDocumentQuery } from 'store/api/documents';
import { AppRoutes } from 'config/routes';
import { Loader } from 'components/loader/loader';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';

export const DetailRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: operationalRequest = {}, isFetching } = useGetOperationalDocumentQuery(
    { documentId: id ?? '' },
    { skip: !id },
  );

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const isOwner = (currentOrganization?.id ?? '') === operationalRequest?.orgOwnerId;

  if (isFetching) {
    return <Loader size="ultra" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.header}>Согласование оперативной заявки</div>
        <div className={styles.contentBox}>
          <div className={styles.card}>
            {Object.entries(operationalRequest).map((entries) => {
              const [key, value] = entries ?? ['', ''];

              return (
                <>
                  <div className={styles.section}>
                    <div className={styles.label}>{key}</div>
                    <div className={styles.value}>{(value as string) ?? ''}</div>
                  </div>
                  <Divider className={styles.divider} />
                </>
              );
            })}
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            onClick={() =>
              navigate(AppRoutes.Dashboard.Home + AppRoutes.Dashboard.OperationalRequests.Home)
            }
            type="tetriary"
            displayType="outlined-tetriary"
          >
            Отмена
          </Button>
          {!isOwner && (
            <>
              <Button
                type="primary"
                displayType="outlined-primary"
                className={styles.editButton}
              >
                Вернуть на корректировку
              </Button>
              <Button type="primary" className={styles.approveButton}>
                Согласовать
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
