import { gql } from 'graphql-request';

export const GET_CORE_DOCUMENTS = gql`
  query GetCoreDocuments(
    $documentIds: [ID!]!
    $outputFormat: core_DocumentsQueryNS_DocumentRenderFormat!
  ) {
    coreDocuments {
      get(ids: $documentIds, format: $outputFormat) {
        id
        typeId
        title
        number
        content
        docDate
        version
        statusId
        createdAt
        updatedAt
        validFrom
        validTo
        signatures {
          id
          documentID
          employeeID
          organizationID
          committed
          type
          createdAt
          updatedAt
          revisions {
            signatureID
            key
            hash
            body
          }
        }
        contractors {
          id
          name
        }
        template {
          id
        }
        orgOwner {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
        }
        parameters {
          name
          value
        }
      }
    }
  }
`;

export const coreDocumentsQuery = {
  query: GET_CORE_DOCUMENTS,
};
